import React from "react";
import MegaMenu from "@insightfulscience/atomic-react/MegaMenu";
import { MegaMenuCol } from "@insightfulscience/atomic-react/MegaMenu/Grid";
import MegaMenuTitle from "@insightfulscience/atomic-react/MegaMenu/Title";
import MegaMenuItem from "@insightfulscience/atomic-react/MegaMenu/Item";
import { useLocale } from "@insightfulscience/smart-react/i18";
import NextLink from "@insightfulscience/smart-react/NextLink";

export const PrismDropdown = () => {
	const [t] = useLocale("header");
	return (
		<MegaMenu>
			<MegaMenuCol md={6} lg={3}>
				<MegaMenuTitle>{t("PRISM_DROPDOWN.FEATURES.INDEX")}</MegaMenuTitle>
				<MegaMenuItem spacing={{ lg: { my: 3 }, md: { my: 0 }, sm: { my: 0 } }}>
					<NextLink
						Component={MegaMenuItem.Link}
						sx={{ fontColor: "black", fontWeight: 400, hover: { fontColor: "2" } }}
						spacing={{ sm: { pb: 0 }, md: { pb: 0 } }}
						href="/features"
					>
						{t("PRISM_DROPDOWN.FEATURES.ITEM_1.TITLE")}
					</NextLink>
					<MegaMenuItem.Description spacing={{ mt: 0 }}>
						{t("PRISM_DROPDOWN.FEATURES.ITEM_1.DESCRIPTION")}
					</MegaMenuItem.Description>
				</MegaMenuItem>
				<MegaMenuItem spacing={{ lg: { my: 3 }, md: { my: 0 }, sm: { my: 0 } }}>
					<NextLink
						Component={MegaMenuItem.Link}
						sx={{ fontColor: "black", fontWeight: 400, hover: { fontColor: "2" } }}
						spacing={{ sm: { pb: 0 }, md: { pb: 0 } }}
						href="/features#analysis"
					>
						{t("PRISM_DROPDOWN.FEATURES.ITEM_2.TITLE")}
					</NextLink>
					<MegaMenuItem.Description spacing={{ mt: 0 }}>
						{t("PRISM_DROPDOWN.FEATURES.ITEM_2.DESCRIPTION")}
					</MegaMenuItem.Description>
				</MegaMenuItem>
				<MegaMenuItem spacing={{ lg: { my: 3 }, md: { my: 0 }, sm: { my: 0 } }}>
					<NextLink
						Component={MegaMenuItem.Link}
						sx={{ fontColor: "black", fontWeight: 400, hover: { fontColor: "2" } }}
						spacing={{ sm: { pb: 0 }, md: { pb: 0 } }}
						href="/features#graphing"
					>
						{t("PRISM_DROPDOWN.FEATURES.ITEM_3.TITLE")}
					</NextLink>
					<MegaMenuItem.Description spacing={{ mt: 0 }}>
						{t("PRISM_DROPDOWN.FEATURES.ITEM_3.DESCRIPTION")}
					</MegaMenuItem.Description>
				</MegaMenuItem>
				<MegaMenuItem spacing={{ lg: { my: 3 }, md: { my: 0 }, sm: { my: 0 } }}>
					<NextLink
						Component={MegaMenuItem.Link}
						sx={{ fontColor: "black", fontWeight: 400, hover: { fontColor: "2" } }}
						spacing={{ sm: { pb: 0 }, md: { pb: 0 } }}
						href="/cloud"
					>
						{t("PRISM_DROPDOWN.FEATURES.ITEM_4.TITLE")}
					</NextLink>
					<MegaMenuItem.Description spacing={{ mt: 0 }}>
						{t("PRISM_DROPDOWN.FEATURES.ITEM_4.DESCRIPTION")}
					</MegaMenuItem.Description>
				</MegaMenuItem>
				<MegaMenuItem spacing={{ lg: { my: 3 }, md: { my: 0 }, sm: { my: 0 } }}>
					<NextLink
						Component={MegaMenuItem.Link}
						sx={{ fontColor: "black", fontWeight: 400, hover: { fontColor: "2" } }}
						spacing={{ sm: { pb: 0 }, md: { pb: 0 } }}
						href="/updates"
					>
						{t("PRISM_DROPDOWN.FEATURES.ITEM_5.TITLE")}
					</NextLink>
					<MegaMenuItem.Description spacing={{ mt: 0 }}>
						{t("PRISM_DROPDOWN.FEATURES.ITEM_5.DESCRIPTION")}
					</MegaMenuItem.Description>
				</MegaMenuItem>
			</MegaMenuCol>
			<MegaMenuCol md={6} lg={3}>
				<MegaMenuTitle>{t("PRISM_DROPDOWN.POPULAR_USE_CASES.INDEX")}</MegaMenuTitle>
				<MegaMenuItem spacing={{ lg: { my: 3 }, md: { my: 0 }, sm: { my: 0 } }}>
					<NextLink
						Component={MegaMenuItem.Link}
						sx={{ fontColor: "black", fontWeight: 400, hover: { fontColor: "2" } }}
						spacing={{ sm: { pb: 0 }, md: { pb: 0 } }}
						href="/features/prism-anova"
					>
						{t("PRISM_DROPDOWN.POPULAR_USE_CASES.ITEM_1.TITLE")}
					</NextLink>
				</MegaMenuItem>
				<MegaMenuItem spacing={{ lg: { my: 3 }, md: { my: 0 }, sm: { my: 0 } }}>
					<NextLink
						Component={MegaMenuItem.Link}
						sx={{ fontColor: "black", fontWeight: 400, hover: { fontColor: "2" } }}
						spacing={{ sm: { pb: 0 }, md: { pb: 0 } }}
						href="/features/power-analysis"
					>
						{t("PRISM_DROPDOWN.POPULAR_USE_CASES.ITEM_2.TITLE")}
					</NextLink>
				</MegaMenuItem>
				<MegaMenuItem spacing={{ lg: { my: 3 }, md: { my: 0 }, sm: { my: 0 } }}>
					<NextLink
						Component={MegaMenuItem.Link}
						sx={{ fontColor: "black", fontWeight: 400, hover: { fontColor: "2" } }}
						spacing={{ sm: { pb: 0 }, md: { pb: 0 } }}
						href="/features/prism-survival-analysis"
					>
						{t("PRISM_DROPDOWN.POPULAR_USE_CASES.ITEM_3.TITLE")}
					</NextLink>
				</MegaMenuItem>
				<MegaMenuItem spacing={{ lg: { my: 3 }, md: { my: 0 }, sm: { my: 0 } }}>
					<NextLink
						Component={MegaMenuItem.Link}
						sx={{ fontColor: "black", fontWeight: 400, hover: { fontColor: "2" } }}
						spacing={{ sm: { pb: 0 }, md: { pb: 0 } }}
						href="/features/prism-nonlinear-regression"
					>
						{t("PRISM_DROPDOWN.POPULAR_USE_CASES.ITEM_4.TITLE")}
					</NextLink>
				</MegaMenuItem>
				<MegaMenuItem spacing={{ lg: { my: 3 }, md: { my: 0 }, sm: { my: 0 } }}>
					<NextLink
						Component={MegaMenuItem.Link}
						sx={{ fontColor: "black", fontWeight: 400, hover: { fontColor: "2" } }}
						spacing={{ sm: { pb: 0 }, md: { pb: 0 } }}
						href="/features/machine-learning"
					>
						{t("PRISM_DROPDOWN.POPULAR_USE_CASES.ITEM_5.TITLE")}
					</NextLink>
				</MegaMenuItem>
			</MegaMenuCol>
		</MegaMenu>
	);
};

export default PrismDropdown;
