import React from "react";
import { bool, node, string } from "prop-types";
import Layout from "@insightfulscience/atomic-react/Layout";
import SiteFooter from "../SiteFooter";
import SiteHeader from "../SiteHeader";
import useIsAuthorized from "../hooks/useIsAuthorized";

const SiteLayoutPure = ({ bodyClassName = "", children, isAuthorized, ...props }) => {
	return (
		<Layout isWrapper>
			<Layout.Header>
				<SiteHeader {...props} isAuthorized={isAuthorized} />
			</Layout.Header>
			<Layout.Body className={bodyClassName}>{children}</Layout.Body>
			<Layout.Footer>
				<SiteFooter />
			</Layout.Footer>
		</Layout>
	);
};

SiteLayoutPure.propTypes = {
	bodyClassName: string,
	isAuthorized: bool,
	children: node,
	hero: bool,
};

const SiteLayout = props => {
	const isAuthorized = useIsAuthorized();
	return <SiteLayoutPure {...props} isAuthorized={isAuthorized} />;
};

SiteLayout.Pure = SiteLayoutPure;

export default SiteLayout;
